<template>
    <section class="events-list-selection">
        <div class="content">
            <PrismicRichText :field="slice.primary.headline" class="title" />
            <Swiper
                slides-per-view="auto"
                :breakpoints="{[breakpoints.mobile]: {spaceBetween: 40}}"
                :space-between="24"
                :modules="MODULES"
                :navigation="true"
            >
                <SwiperSlide
                    v-for="(item, index) in slice.items.filter((item) => isFilled.contentRelationship(item.guide))"
                    :key="`event-${index}`"
                    class="slide"
                >
                    <GuideCard :guide="(item.guide as FilledContentRelationshipField<'guide', string , GuideDocumentData> )" />
                </SwiperSlide>
            </Swiper>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {useRuntimeConfig} from '#imports';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {GuideDocumentData, GuidesListSliceSelection} from '@prismicio-types';
    import {PrismicRichText} from '@prismicio/vue';
    import {Swiper, SwiperSlide} from 'swiper/vue';
    // @ts-ignore
    import {Navigation} from 'swiper';
    import GuideCard from '~/components/GuideCard.vue';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import {isFilled} from '@prismicio/helpers';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {FilledContentRelationshipField} from '@prismicio/types';
    const MODULES = [Navigation];

    defineProps<{slice: GuidesListSliceSelection}>();
    const {breakpoints} = useRuntimeConfig().public;
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .events-list-selection {
        overflow: hidden;
        position: relative;
        .content {
            @include content-width;
        }
        .title h2 {
            margin-bottom: var(--joy-core-spacing-9);
        }
        .swiper {
            overflow: visible;
            margin-right: 40px;
            padding-bottom: 80px;
            @media (max-width: $mobile-breakpoint) {
                margin-right: 0;
            }
        }
        .swiper-wrapper {
            overflow: visible;
        }
        .slide {
            width: auto;
            max-width: 400px;

            @media (max-width: $mobile-breakpoint) {
                width: 250px;
            }
        }
        .swiper-button-prev,
        .swiper-button-next {
            content: url('~/assets/svg/arrowRight.svg');
            padding: var(--joy-core-spacing-3);
            border-radius: 50%;
            height: 42px;
            width: 42px;
            background-color: var(--joy-color-primary-50);
            position: absolute;
            top: unset;
            bottom: 10px;
            left: unset;
            right: 0;
        }
        .swiper-button-prev {
            transform: rotate(180deg);
            left: unset;
            right: 60px;
        }
    }
</style>
