<template>
    <section class="guides-list">
        <div class="content">
            <PrismicRichText :field="slice.primary.title" class="title" />
            <PrismicRichText :field="slice.primary.headline" class="headline" />
        </div>
        <div class="guides">
            <div class="content">
                <div class="guides-grid">
                    <GuideCard v-for="guide in guides" :key="guide.uid" :guide="guide" />
                </div>
                <div class="button-row">
                    <NuxtLink v-slot="{href, navigate}" to="/resources/guides" custom>
                        <VJoyButton variant="main" :href="href" @click="navigate">{{ t('resources.guides.seeAll') }}</VJoyButton>
                    </NuxtLink>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {PrismicRichText} from '@prismicio/vue';
    import type {GuideDocument, GuideDocumentData, GuidesListSliceDefault} from '@prismicio-types';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import {isFilled} from '@prismicio/helpers';
    import {useAsyncData, useTranslation} from '#imports';
    import usePrismicSearch from '~/composables/content/usePrismicSearch';
    import GuideCard from '~/components/GuideCard.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const props = defineProps<{slice: GuidesListSliceDefault}>();
    const guides = [props.slice.primary.guide1, props.slice.primary.guide2, props.slice.primary.guide3].filter((article) =>
        isFilled.contentRelationship<'guide', string, GuideDocumentData>(article),
    ) as (FilledContentRelationshipField<'guide', string, GuideDocumentData> | GuideDocument)[];

    const {t} = useTranslation();

    function fetchGuides() {
        if (guides.length < 3) {
            // prismic does not allow 'or' queries, so we have to do multiple queries
            const searchQueries = props.slice.items
                .filter((searchFilter) => searchFilter.categoryFilter || searchFilter.tagFilters)
                .map((item) =>
                    usePrismicSearch({
                        type: 'guide',
                        pageSize: 3 - guides.length,
                        category: item.categoryFilter ?? undefined,
                        tags: item.tagFilters?.split(', '),
                        excludes: guides.map((guide) => guide.id),
                    }),
                );
            if (searchQueries.length === 0) {
                searchQueries.push(
                    usePrismicSearch({
                        type: 'guide',
                        pageSize: 3 - guides.length,
                        excludes: guides.map((guide) => guide.id),
                    }),
                );
            }
            return Promise.all(searchQueries);
        }
        return Promise.resolve([]);
    }

    const {data: complementaryGuides} = await useAsyncData('complementary-guides', fetchGuides);

    if (complementaryGuides.value) {
        const mostRecentComplementaryGuides = complementaryGuides.value
            .map((query) => query?.results)
            .flat()
            .filter((guide) => guide !== undefined)
            .sort((a, b) => new Date(b?.data?.releaseDate).getTime() - new Date(a?.data?.releaseDate).getTime())
            .slice(0, 3 - guides.length) as GuideDocument[];
        guides.push(...mostRecentComplementaryGuides);
    }
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .guides-list {
        background-color: white;
        position: relative;
        padding: 150px 0px;
        @media (max-width: $mobile-breakpoint) {
            padding: 80px 0px;
        }

        .guides {
            position: relative;

            .guides-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: var(--joy-core-spacing-9);
                justify-items: stretch;
                position: static;
                z-index: 1;
                position: relative;
                overflow: show;

                @media (max-width: $mobile-breakpoint) {
                    grid-template-columns: 1fr;
                }
            }
        }
        .content {
            @include content-width;
            .title p {
                text-align: center;
                color: var(--joy-color-primary-50);
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-secondary-200);
                margin-bottom: var(--joy-core-spacing-5);
            }
            .headline {
                text-align: center;
                margin-bottom: var(--joy-core-spacing-11);
            }
            .button-row {
                display: flex;
                justify-content: center;
                margin-top: 80px;
                @media (max-width: $mobile-breakpoint) {
                    margin-top: var(joy-core-spacing-12);
                }
            }
        }
    }
</style>
